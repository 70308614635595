<template>
  <div class="workflow-edit-navbar">
    <template v-if="!isPreview && !workflow.isSnapshot">
      <online-users :members="members"></online-users>

      <div class="divider"></div>

      <div class="workflow-history">
        <button
          class="btn btn-borderless"
          :disabled="canUndo"
          @click="workflowApi.undo()"
        >
          <span
            class="glyphicon glyphicon-share-alt"
            style="transform: rotateY(180deg)"
          ></span>
        </button>
        <button
          class="btn btn-borderless"
          :disabled="canRedo"
          @click="workflowApi.redo()"
        >
          <span class="glyphicon glyphicon-share-alt"></span>
        </button>
      </div>

      <div class="divider"></div>
    </template>

    <button
      v-if="!workflow.isSnapshot"
      class="btn btn-borderless"
      style="margin-right: 10px"
      @click="eventBus.$emit('showWorkflowReporting', true)"
    >
      <icon glyph="bar-chart" />
    </button>

    <div
      :class="{
        'workflow-status': true,
        'is-preview': isPreview || workflow.isSnapshot
      }"
    >
      <el-dropdown placement="bottom-start" size="small" trigger="click">
        <button
          class="btn btn-status dropdown-toggle"
          :class="buttonClass"
          type="button"
        >
          {{ status.messages.length }}
        </button>
        <el-dropdown-menu class="dropdown-items" slot="dropdown">
          <el-dropdown-item v-if="!status.messages.length">
            <a>No errors</a>
          </el-dropdown-item>
          <el-dropdown-item
            v-for="(message, index) in status.messages"
            :key="index"
            class="message"
          >
            <a @click="focusAction(message.id)">
              <div class="message-icon" :class="messageClass(message)"></div>
              <el-tooltip
                popper-class="error-msg-tooltip"
                :disabled="message.desc.length < 40"
              >
                <div slot="content">{{ $t(message.desc) }}</div>
                <span class="message-text">{{ $t(message.desc) }}</span>
              </el-tooltip>
              <button
                v-if="message.category === 'SEND-CREDITS'"
                class="btn btn-xs btn-primary"
                @click="actionUpgrade"
              >
                Upgrade
              </button>
            </a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <template v-if="!isPreview && !workflow.isSnapshot">
      <div class="divider"></div>

      <div class="workflow-save-status">
        <span v-if="status.saving">{{ $t('workflowNavSaving') }}</span>
        <span v-else>{{ $t('workflowChangesSaved') }}</span>
      </div>
    </template>
  </div>
</template>
<script>
import OnlineUsers from './OnlineUsers'
import alert from '@/libs/Alert'

export default {
  props: ['workflow', 'status', 'history', 'members', 'isPreview'],

  inject: ['workflowApi', 'eventBus'],

  components: {
    OnlineUsers
  },

  data() {
    return {
      toolboxOpen: false,
      actionId: null
    }
  },

  created() {
    this.eventBus.$on('largeToolboxOpen', (value) => (this.toolboxOpen = value))
    this.eventBus.$on(
      'multidecision-successor-change-disable-all',
      (actionId) => (this.actionId = actionId)
    )
  },

  computed: {
    buttonClass: function () {
      if (this.errors.length) {
        return 'btn-danger'
      } else if (this.warnings.length) {
        return 'btn-warning'
      }

      return 'btn-success'
    },

    errors: function () {
      return this.status.messages.filter((message) => message.type === 'error')
    },

    warnings: function () {
      return this.status.messages.filter(
        (message) => message.type === 'warning'
      )
    },

    canUndo() {
      return (
        this.workflow.isRunning ||
        this.status.saving ||
        !this.history.hasUndo ||
        this.toolboxOpen
      )
    },

    canRedo() {
      return (
        this.workflow.isRunning ||
        this.status.saving ||
        !this.history.hasRedo ||
        this.toolboxOpen
      )
    }
  },

  methods: {
    actionUpgrade() {
      alert.show({
        title: 'Upgrade Account',
        htmlMessage: true,
        message: `To upgrade your account please contact us on <a href='tel:+441327811884'>+441327811884</a>.`
      })
    },

    messageClass(message) {
      const messageClass = {
        error: 'btn-danger',
        warning: 'btn-warning',
        ok: 'btn-success'
      }

      return messageClass[message.type]
    },

    focusAction(actionId) {
      this.eventBus.$emit('selectAction', actionId)
    }
  },

  watch: {
    'status.saving': {
      handler(isSaving) {
        if (!isSaving) {
          this.eventBus.$emit(
            'multidecision-endpoint-update-finish',
            this.actionId
          )
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.dropdown-items
    li:hover
        color: #636363
        background-color: #f5f5f5
    li a
        color: #707070
    li a:hover
        text-decoration: none

.btn.btn-borderless
    background-color: transparent
    &:hover
        color: inherit
        border-color: #ccc

        &:disabled
            border-color: transparent

.workflow-edit-navbar
    z-index: 5
    position: fixed
    width: 100%
    top: 0px
    background-color: #ECECEC
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25)
    height: 50px
    display: flex
    align-items: center
    justify-content: flex-end

    .divider
        width: 0px
        border-left: 1px solid #ccc
        height: 100%
        margin: 0 15px

    .workflow-status
        .btn-status
            transition: background-color .3s ease
            border: 1px solid #c3c3c3

        .loading
            color: transparent

    .workflow-status.is-preview
        margin-right: 200px

    .workflow-save-status
        width: 130px
        font-size: 12px

.message
    cursor: pointer
    max-width: 305px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    .message-icon
        width: 12px
        height: 12px
        display: inline-block
        border-radius: 50%
        margin-right: 5px

    .message-text
        margin-right: 30px
</style>
<style>
.error-msg-tooltip {
  max-width: 300px;
}
</style>
